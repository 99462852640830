import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { gql, useMutation } from '@apollo/client'
import ReactJson from '@microlink/react-json-view'
import { Box, Button, TextField, Typography } from '@mui/material'
import { IconInbox } from '@tabler/icons-react'
import toast from 'react-hot-toast'
import type {
  Action,
  ActionChannelType,
  ActionPromptOutput,
  CRMObject,
  UpdateActionInput,
} from 'types/graphql'
import type { ActionType } from 'types/graphql'

import { useAuth } from 'src/auth'
import { actionQueries } from 'src/lib/actions'
import { DayContext } from 'src/lib/dayContext'
import { ungatedForTemplates } from 'src/lib/gates'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import ActionActions from '../Actions/ActionActions/ActionActions'
import ActionPriorityChip from '../Actions/ActionPriorityChip/ActionPriorityChip'
import PersonChip from '../People/PersonChip/PersonChip'
import PromptInput from '../Prompts/PromptInput'
import Row from '../Row/Row'

import { typeLabels } from './inbox'
import { CREATE_ACTION_DRAFT } from './mutations'

const MARK_ACTION_READ = gql`
  mutation MarkActionRead($input: UpdateActionInput!) {
    updateActionByUser(input: $input)
  }
`

const actionSourceTypeMap = {
  ['MEETING_RECORDING']: NativeObjectTypes.MeetingRecording,
  ['GMAIL_THREAD']: NativeObjectTypes.GmailThread,
  ['SLACK_CHANNEL']: NativeObjectTypes.SlackChannel,
  ['OPPORTUNITY']: NativeObjectTypes.Opportunity,
}

// Initial context objects based on action properties
const getContextObjectsFromAction = (
  action: Action,
  workspaceId: string
): CRMObject[] => {
  if (!action || !workspaceId) {
    return []
  }

  return [
    ...(action.people?.map((p: { email: string }) => ({
      objectId: p.email,
      objectType: NativeObjectTypes.Contact,
      workspaceId,
    })) || []),
    ...(actionSourceTypeMap[action.source?.type]
      ? [
          {
            objectId: action.source.id,
            objectType: actionSourceTypeMap[action.source.type],
            workspaceId,
          },
        ]
      : []),
  ]
}

// Initial dismissed objects based on action properties
const getDismissedObjectsFromAction = (
  action: Action | null | undefined,
  workspaceId: string | null | undefined
): CRMObject[] => {
  if (!action || !workspaceId) {
    return []
  }
  return [
    {
      objectId: action.id,
      objectType: NativeObjectTypes.Action,
      workspaceId,
    },
  ]
}

const InboxActionDetail = ({
  selectedAction,
  refetch,
  type,
  updateAction,
  showCrmObjects = false,
  onHide = (_actionId: string) => {},
}: {
  selectedAction: Action
  refetch: () => void
  type: ActionType
  updateAction: (action: UpdateActionInput) => void
  showCrmObjects: boolean
  onHide: (actionId: string) => void
}) => {
  const { currentUser } = useAuth()
  const { selectedWorkspace, setSidebarObject } = useContext(DayContext)
  const [editingTitle, setEditingTitle] = useState(false)
  const [title, setTitle] = useState(selectedAction?.title || '')
  const [selectedChannelType, setSelectedChannelType] =
    useState<ActionChannelType | null>(
      !ungatedForTemplates(currentUser)
        ? (selectedAction?.draft?.prompts?.[0]
            ?.channelType as ActionChannelType)
        : null
    )

  const [contextObjects, setContextObjects] = useState<CRMObject[]>(() =>
    getContextObjectsFromAction(selectedAction, selectedWorkspace)
  )
  const [dismissedObjects, setDismissedObjects] = useState<CRMObject[]>(() =>
    getDismissedObjectsFromAction(selectedAction, selectedWorkspace)
  )

  // Update state if selectedAction changes after the first render. We need this
  // for the case when the sidebar is rendered with only an action id and we
  // need to wait for the action to be fetched from the server.
  useEffect(() => {
    const newContextObjects = getContextObjectsFromAction(
      selectedAction,
      selectedWorkspace
    )
    setContextObjects(newContextObjects)

    const newDismissedObjects = getDismissedObjectsFromAction(
      selectedAction,
      selectedWorkspace
    )
    setDismissedObjects(newDismissedObjects)
  }, [selectedAction, selectedWorkspace])

  const handleSetContextObjects = useCallback(
    ({ contextObjects, dismissedObjects }) => {
      if (contextObjects || contextObjects.length === 0) {
        setContextObjects(contextObjects)
      }
      if (dismissedObjects || dismissedObjects.length === 0) {
        setDismissedObjects(dismissedObjects)
      }
    },
    []
  )

  const isActionOwner = useMemo(() => {
    return selectedAction?.owner?.email === currentUser?.email
  }, [selectedAction, currentUser])

  const [markRead] = useMutation(MARK_ACTION_READ, {
    refetchQueries: actionQueries,
    awaitRefetchQueries: true,
  })

  const [createActionDraft] = useMutation(CREATE_ACTION_DRAFT)

  const onSubmit = useCallback(
    async (state, _dispatch) => {
      if (!state.prompt.trim()) {
        return
      }

      const finalTemplate = ungatedForTemplates(currentUser)
        ? state.template
        : {
            templateType: selectedChannelType,
          }

      // add the parent action to the page context
      contextObjects.push({
        objectId: selectedAction.id,
        objectType: NativeObjectTypes.Action,
        workspaceId: selectedWorkspace,
      })

      try {
        const result = await toast.promise(
          createActionDraft({
            variables: {
              input: {
                workspaceId: selectedWorkspace,
                title: state.prompt.split('\n')[0].substring(0, 50),
                actionId: selectedAction.id,
                contextObjects: contextObjects?.map((object) => ({
                  objectType: object.objectType,
                  objectId: object.objectId,
                  workspaceId: selectedWorkspace,
                })),
                dismissedObjects: dismissedObjects
                  ?.map((object) => ({
                    objectType: object.objectType,
                    objectId: object.objectId,
                    workspaceId: selectedWorkspace,
                  }))
                  .filter((object) => object.objectId !== selectedAction.id),
                sourceTemplate: {
                  id: finalTemplate?.id,
                  templateType: finalTemplate?.templateType,
                  contentHtml: finalTemplate?.contentHtml,
                },
                userPrompt: state.prompt,
              },
            },
          }),
          {
            loading: 'Creating page...',
            success: 'Page created!',
            error: 'Failed to create page',
          }
        )

        if (result.data?.createActionDraft) {
          const newSidebarPageObject = {
            objectId: result.data.createActionDraft.id,
            objectType: NativeObjectTypes.Page,
            workspaceId: selectedWorkspace,
          }

          setSidebarObject(newSidebarPageObject)
        }
      } catch (error) {
        logger.error('Failed to create page:', error)
      }
    },
    [
      createActionDraft,
      contextObjects,
      dismissedObjects,
      setSidebarObject,
      selectedWorkspace,
      selectedChannelType,
      currentUser,
      selectedAction,
    ]
  )

  useEffect(() => {
    setTitle(selectedAction?.title || '')
    setEditingTitle(false)

    // Mark action as READ when mounted if user is the owner and status is UNREAD
    if (
      selectedAction?.id &&
      isActionOwner &&
      selectedAction?.status?.id === 'UNREAD'
    ) {
      logger.dev('Marking action as read:', {
        actionId: selectedAction.id,
        workspaceId: selectedAction.workspaceId,
      })

      markRead({
        variables: {
          input: {
            id: selectedAction.id,
            workspaceId: selectedAction.workspaceId,
            status: 'READ',
          },
        },
      }).catch((error) => {
        logger.error('Failed to mark action as read:', error)
      })
    }
  }, [selectedAction, isActionOwner, markRead, currentUser?.email])

  const handleUpdateAction = async () => {
    if (!selectedAction) return
    setEditingTitle(false)

    await updateAction({
      id: selectedAction.id,
      title,
      workspaceId: selectedAction.workspaceId,
    })
  }

  return selectedAction?.id ? (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        maxHeight: 'calc(100vh - 64px)',
        overflowY: 'auto',
        px: 1,
      }}
    >
      <Row
        sx={{
          height: '56px',
          justifyContent: 'space-between',
        }}
      >
        <Row gap={1}>
          <ActionPriorityChip action={selectedAction} />
          <ActionActions
            action={selectedAction}
            showButtons={true}
            onUpdate={() => {
              refetch()
            }}
            onHide={(actionId) => {
              onHide(actionId)
            }}
          />
        </Row>
        <Row gap={1}>
          {selectedAction.owner?.email && (
            <PersonChip
              email={selectedAction.owner.email}
              sx={{
                border: 'none',
                px: 0,
                '& .MuiChip-label': {
                  pr: 0,
                },
              }}
            />
          )}
        </Row>
      </Row>

      {editingTitle ? (
        <Box>
          <TextField
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth={true}
            variant="standard"
            multiline={true}
            rows={title?.length > 64 ? 2 : 1}
            inputProps={{
              style: {
                fontSize: '24px',
                fontWeight: 600,
                letterSpacing: '-1.2px',
                lineHeight: '30px',
              },
            }}
          />
          <Row sx={{ justifyContent: 'flex-end' }}>
            <Button onClick={handleUpdateAction}>Save</Button>
          </Row>
        </Box>
      ) : (
        <Typography
          variant="h2"
          sx={{ mt: 1, mb: showCrmObjects ? 2 : 4 }}
          onClick={() => setEditingTitle(true)}
        >
          {title}
        </Typography>
      )}
      <PromptInput
        parentObject={{
          objectType: NativeObjectTypes.Action,
          objectId: selectedAction?.id,
        }}
        placeholder={"Let's take action. What should we make?"}
        suggestedPrompts={
          selectedAction?.draft?.prompts as ActionPromptOutput[]
        }
        workspaceId={selectedAction?.workspaceId}
        selectedChannelType={selectedChannelType}
        setSelectedChannelType={setSelectedChannelType}
        contextObjects={contextObjects}
        dismissedObjects={dismissedObjects}
        handleSetContextObjects={handleSetContextObjects}
        onSubmit={onSubmit}
      />

      {false && process.env.HOST.includes('localhost') && (
        <ReactJson
          src={selectedAction}
          collapsed={0}
        />
      )}
    </Box>
  ) : type ? (
    <Row sx={{ height: '90%', justifyContent: 'center', opacity: 0.5 }}>
      <Row
        sx={{ flexDirection: 'column', alignItems: 'center' }}
        gap={1}
      >
        <IconInbox size={72} />
        <Typography variant="h3">{typeLabels[type].label} Inbox</Typography>
      </Row>
    </Row>
  ) : null
}

export default InboxActionDetail
